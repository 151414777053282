var items = [];
const userData = JSON.parse(localStorage.getItem("userData"));
if(userData && userData.role === "admin"){
    items = [
        {
            path: '/create-notification',
            name: 'create-notification',
            component: () => import('@/views/pages/notification/CreateNotification'),
        },
    ]
}
export default items;