var items = [];
const userData = JSON.parse(localStorage.getItem("userData"));
if (userData && userData.role === "admin") {
    items = [
        {
            path: '/all-users',
            name: 'all-users',
            component: () => import('@/views/pages/user/Index'),
        },
        {
            path: '/create-user',
            name: 'create-user',
            component: () => import('@/views/pages/user/CreateUser'),
        },
        {
            path: '/update-user/:id',
            name: 'update-user',
            component: () => import('@/views/pages/user/Update'),
        },
    ]
}
items.push(
    {
        path: '/my-profile/edit/change-password',
        name: 'change-password',
        component: () => import('@/views/pages/my-profile/ChangePassword')
    });
export default items;