import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import Pages from './routes/users routes/pages'
import ChatRoute from './routes/chats routes/pages';
import TopicRoute from './routes/topic routes/pages';
import ExternalEventRoute from './routes/external event routes/pages';
import AdminRoute from './routes/admins routes/pages';
import StepRoute from './routes/steps routes/pages';
import PrivacyRoute from './routes/privacy routes/pages';
import NotificationRoute from './routes/notification routes/pages';
import EventRoute from './routes/event routes/pages';

const userData = JSON.parse(localStorage.getItem("userData"));
if(userData) var role = userData.role



Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {path: '/', redirect: userData ? {name: role && role === "admin" ? 'all-users' : "external-event"} : "" },
        ...Pages,
        ...apps,
        ...dashboard,
        ...pages,
        ...chartsMaps,
        ...formsTable,
        ...uiElements,
        ...others,
        ...ChatRoute,
        ...StepRoute,
        ...PrivacyRoute,
        ...NotificationRoute,
        ...AdminRoute,
        ...ExternalEventRoute,
        ...EventRoute,
        ...TopicRoute,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({name: 'auth-login'})

        // If logged in => not authorized
        return next({name: 'misc-not-authorized'})
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
