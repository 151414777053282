var items = [];
const userData = JSON.parse(localStorage.getItem("userData"));
if(userData && userData.role === "admin"){
    items = [
        {
            path: '/privacyPolicy',
            name: 'privacyPolicy',
            component: () => import('@/views/pages/privacy/Index'),
        },
        {
            path: '/update-privacy/:id',
            name: 'update-privacy',
            component: () => import('@/views/pages/privacy/Update'),
        },
        {
            path: '/terms',
            name: 'terms',
            component: () => import('@/views/pages/privacy/IndexTerms'),
        },
        {
            path: '/guidelines',
            name: 'guidelines',
            component: () => import('@/views/pages/privacy/IndexGuidelines'),
        },
        {
            path: '/FAQs',
            name: 'FAQs',
            component: () => import('@/views/pages/FAQs/Index'),
        },
        {
            path: '/create-FAQs',
            name: 'create-FAQs',
            component: () => import('@/views/pages/FAQs/CreateFAQs'),
        },
        {
            path: '/update-FAQs/:id',
            name: 'update-FAQs',
            component: () => import('@/views/pages/FAQs/Update'),
        },
    ]
}
export default items;