var items = [];
const userData = JSON.parse(localStorage.getItem("userData"));
if(userData && userData.role === "admin") {
    items = [
        {
            path: '/event',
            name: 'event',
            component: () => import('@/views/pages/event/Index'),
        },
        {
            path: '/create-event',
            name: 'create-event',
            component: () => import('@/views/pages/event/CreateEvent'),
        },
        {
            path: '/update-event/:id',
            name: 'update-event',
            component: () => import('@/views/pages/event/Update'),
        },
    ]
}

export default items;