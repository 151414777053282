var items = [];
const userData = JSON.parse(localStorage.getItem("userData"));
if(userData && userData.role === "admin"){
    items = [
        {
            path: '/steps',
            name: 'steps',
            component: () => import('@/views/pages/step/Index'),
        },
        {
            path: '/update-step/:id',
            name: 'update-step',
            component: () => import('@/views/pages/step/Update'),
        },
    ]
}
export default items;