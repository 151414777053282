var items = [];
const userData = JSON.parse(localStorage.getItem("userData"));
if(userData && userData.role === "eventManager") {
    items = [
        {
            path: '/external-event',
            name: 'external-event',
            component: () => import('@/views/pages/external-event/Index'),
        },
        {
            path: '/create-external-event',
            name: 'create-external-event',
            component: () => import('@/views/pages/external-event/CreateExternalEvent'),
        },
        {
            path: '/update-external-event/:id',
            name: 'update-external-event',
            component: () => import('@/views/pages/external-event/Update'),
        },
    ]
}

export default items;