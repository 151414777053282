var items = [];
const userData = JSON.parse(localStorage.getItem("userData"));
if(userData && userData.role === "admin"){
    items = [
        {
            path: '/admin-management',
            name: 'admin-management',
            component: () => import('@/views/pages/admin-management/Index'),
        },
        {
            path: '/create-admin',
            name: 'create-admin',
            component: () => import('@/views/pages/admin-management/CreateAdmin'),
        },
        {
            path: '/update-admin/:id',
            name: 'update-admin',
            component: () => import('@/views/pages/admin-management/Update'),
        },
    ]
}
export default items;