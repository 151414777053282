var items = [];
const userData = JSON.parse(localStorage.getItem("userData"));
if(userData && userData.role === "admin"){
    items = [
        {
            path: '/topics',
            name: 'topics',
            component: () => import('@/views/pages/topic/Index'),
        },
        {
            path: '/create-topic',
            name: 'create-topic',
            component: () => import('@/views/pages/topic/CreateTopic'),
        },
        {
            path: '/update-topic/:id',
            name: 'update-topic',
            component: () => import('@/views/pages/topic/Update'),
        },
    ]
}
export default items;